* {
  box-sizing: border-box !important;

}
@layer base {
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  h4 {
    @apply text-2xl;
  }
  h5 {
    @apply text-xl;
  }
  h6 {
    @apply text-lg;
  }

  a {
    @apply cursor-pointer;
  }
}


@tailwind base;
 
@tailwind components;
 
@tailwind utilities;






@import url('./sass/styles.css');


*{
  
}








